@import '@/_styles/import';
.AdvancedFilter__section {
  overflow: unset;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;

  background: linear-gradient(to left, $white, $white 50%, $white 76.8%, $background-color 75%);
  box-shadow:
    0 2px 8px 0 $black-0004,
    0 4px 20px 0 $black-0004;

  @include media-breakpoint-down(sm) {
    z-index: 99999 !important;
    width: 100%;
    background: $white;
  }

  .AdvancedFilter__main {
    overflow-y: auto;

    width: 100%;
    height: 100%;
    padding-bottom: var(--adv-filter-body-gap-bottom);

    transition: width 0.3s;
  }

  .AdvancedFilter__header {
    position: fixed;
    z-index: 1;
    top: 0;

    display: flex;

    width: inherit;
    height: var(--adv-filter-header-height);
    margin: 0 auto;

    text-align: center;

    @include media-breakpoint-down(sm) {
      line-height: var(--adv-filter-header-height);
      background-color: $gray-085;
      border-bottom: 1px solid $lightwhite;
    }

    .close {
      float: left;

      width: 130px;
      height: 90px;
      padding: 26px;
      padding-top: 15px;

      font-size: 16px;
      color: $gray-light;
      text-align: left;

      background: linear-gradient(180deg, $background-color, $gray-light-2 100%);
      border: 0;

      p {
        cursor: pointer;

        width: 24px;
        height: 24px;

        font-size: 24px;
        line-height: 24px;
      }

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .close-mobile {
      position: absolute;
      right: 27.5px;

      display: none;

      max-height: 100%;

      background: $white;
      border: 0;

      @include media-breakpoint-down(sm) {
        display: block;
        background-color: $background-color;
      }

      .Icon {
        color: $black;
      }
    }

    h2.title {
      width: 413px;
      height: 90px;
      padding-top: 40px;
      padding-bottom: 25px;

      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
      color: $black;
      text-align: center;
      letter-spacing: 0;

      background-color: $background-color;

      @include media-breakpoint-down(sm) {
        width: 100%;
        height: auto;
        margin: 0;
        padding-top: 0;

        line-height: 60px;
      }

      @include media-breakpoint-down(xl) {
        width: 430px;
      }
    }
  }

  button.ClassBtn {
    z-index: 9999;
    top: 0;
    left: 0;

    width: 130px;
    height: 90px;

    background: linear-gradient(180deg, $gray-248 0.04%, $gray-light-2 100%);
  }

  .AdvancedFilter__body {
    position: relative;
    width: 100%;
    min-height: 100%;
    padding: calc(var(--adv-filter-body-gap-top) + var(--adv-filter-header-height) + 2px) 0 0;

    @include media-breakpoint-down(sm) {
      padding-right: rem(30);
      padding-left: rem(30);
      background: $white;
    }

    .TravelParty__main,
    .DurationFilter__main,
    .CabinTypeFilter__main,
    .PriceRangeFilter__main,
    .ShipFilter__main,
    .DeparturePortFilter__main {
      margin: 0 0 0 20px;
      padding-right: 0;
      background: linear-gradient(to left, $white, $white 79%, $gray-light-2 64%);

      @include media-breakpoint-down(sm) {
        margin: 0;
        background: $white;
      }
    }
  }

  .row {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    margin: 0;

    @include media-breakpoint-down(sm) {
      justify-content: space-between;
      border-bottom: 1px solid $gray-010;
    }

    .col.col1 {
      cursor: pointer;

      flex-basis: unset;
      flex-grow: unset;

      height: auto;
      margin-bottom: 0;
      padding: 0;

      letter-spacing: 0;

      background: $white;

      @include font($size: 13px, $line-height: 64px, $weight: 500, $color: $grey-text);

      @include media-breakpoint-up(md) {
        flex-shrink: 0;
        width: 110px;
        background: $gray-light-2;
      }
    }

    .col.col2 {
      display: flex;
      flex-direction: column;
      font-weight: 500;
      background-color: $white;

      &:focus-visible {
        border-radius: 3px;
        outline: 2px solid black;
        outline-offset: -0.19rem;
      }

      @include media-breakpoint-up(md) {
        justify-content: center;

        width: 77.7%;
        margin-right: 15px;

        border-bottom: 1px solid $lightwhite;
        box-shadow: -4px 0 8px 0 $black-0004;
      }

      @include media-breakpoint-down(sm) {
        padding: 0;
      }

      .header {
        cursor: pointer;
        display: flex;
        width: 100%;
        padding: 0;

        @include media-breakpoint-up(md) {
          margin-right: 20px;
        }

        p.value {
          width: 95%;
          height: auto;
          margin: 16px 0;
          text-align: center;

          span {
            display: flex;
            flex-direction: row;
            place-content: center center;

            width: max-content;
            max-width: 350px;
            height: auto;
            margin: 0 auto;
            padding: 12px;

            font-size: rem(12);
            font-weight: 500;
            line-height: rem(16);
            color: $grey-text;
            text-align: center;
            letter-spacing: 0;

            background-color: $lightwhite;
            border-radius: 15px;

            @include media-breakpoint-down(sm) {
              max-width: 170px;
            }

            &.loading-skeleton {
              border-radius: 0;
            }
          }
        }

        .action {
          float: right;
          text-align: right;
          background: none;
          border: 0;

          @include media-breakpoint-down(sm) {
            padding-right: 0;
          }

          span.Icon.Caret {
            height: 64px;
            line-height: 64px;
            color: $primary-red;

            @include media-breakpoint-down(sm) {
              display: none;
            }

            span {
              height: 100%;
            }
          }
        }
      }

      .content {
        margin-top: 10px;

        .action {
          width: 395px;
          margin: 0 auto 20px;
        }
      }

      .img {
        margin-bottom: 5px;
        margin-left: 3em;
      }

      .AdvancedRefinement__nights {
        margin-left: 2em;
      }

      .AdvancedRefinement__weekend {
        width: 200px;
        height: 24px;
        margin: 0 auto 10px;

        .weekend-form-check {
          label {
            cursor: pointer;
          }
        }
      }

      .subtitle {
        width: 250px;
        height: 40px;
        margin: 0 auto 21px;

        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: $grey-text;
        text-align: center;
        letter-spacing: 0;
      }
    }
  }

  .AdvancedFilter__footer {
    z-index: $z-index-AF-footer;
    bottom: 0;
    height: var(--adv-filter-footer-height);
    background-color: $white;

    @include media-breakpoint-down(sm) {
      padding: 0 6.5%;
    }

    .row {
      display: flex;
      align-items: center;

      width: inherit;
      height: 100%;
      margin: 0;

      @include media-breakpoint-down(sm) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }

      .col.col1 {
        cursor: unset;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 130px;
        height: 100%;

        font-size: rem(15);
        font-weight: 400;
        color: $black;
        text-decoration: underline;
        letter-spacing: 0;

        background: linear-gradient(180deg, rgb(248 248 248 / 13700%) 0.04%, $gray-light-2 100%);
        border: 0;

        &[role='button'] {
          cursor: pointer;
        }

        @include media-breakpoint-down(sm) {
          width: auto;
          background: $white;
        }

        &.FooterActionBarResetButton {
          cursor: pointer;
        }
      }

      .col.col2 {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 445px;
        margin: 0 auto;

        background: linear-gradient(180deg, $gray-000 0.04%, $white 100%);
        box-shadow: none;

        @include media-breakpoint-down(sm) {
          flex-grow: 0;
          width: auto;
          height: var(--adv-filter-footer-height);
          margin: 0;
        }

        button {
          width: 345px;
          height: var(--adv-filter-footer-btn-height);
          margin: 0 auto;
          border-radius: 4px;

          @include media-breakpoint-down(sm) {
            width: auto;
          }
        }
      }
    }
  }

  .DurationFilter__main {
    .col.col2 {
      box-shadow: -2px 6px 8px 0 $black-0004;

      @include media-breakpoint-down(sm) {
        box-shadow: unset;
      }
    }
  }

  .CabinTypeFilter__main {
    .col.col2 {
      .content {
        .action {
          padding-left: 15px;
        }

        .CabinTypeRefinement__card {
          cursor: pointer;

          position: unset;

          display: flex;
          flex-direction: row;

          width: 345px;
          height: auto;
          margin-bottom: 12px;

          text-align: unset;

          box-shadow: unset;

          .CabinTypeRefinement__cardRadio {
            position: unset;
            width: 10%;

            .form-check-input[type='radio'] ~ label::before {
              cursor: pointer;
            }
          }

          .CabinTypeRefinement__cardImage {
            display: flex;

            .CabinTypeRefinement__image {
              flex: unset;
              width: 81px;
              height: 60px;
              border-radius: 2px;
            }
          }

          .CabinTypeRefinement__cardDesc {
            width: 220px;
            height: 60px;
            margin-left: 10px;

            .CabinTypeRefinement__name {
              width: 220px;
              height: 24px;

              font-size: 15px;
              font-weight: 400;
              line-height: 24px;
              color: $black;
              letter-spacing: 0;
            }

            .CabinTypeRefinement__description,
            .price {
              width: 220px;
              height: 20px;
              margin: 0;

              font-size: 13px;
              font-weight: 400;
              line-height: 20px;
              color: $grey-text;
              text-align: left;
              letter-spacing: 0;
            }
          }

          p.default {
            width: 220px;
            margin-top: 0;
            margin-bottom: 0;

            font-size: 15px;
            font-weight: bold;
            line-height: 50px;
            color: $black;
            letter-spacing: 0;
          }
        }
      }
    }
  }

  .CabinTypeRefinement__name.selected {
    font-weight: bold !important;
  }

  .CabinTypeRefinement__description.default {
    &.selected {
      font-weight: 700;
      color: $black;
    }

    &.not-selected {
      font-weight: 400;
      color: $lightgrey2 !important;
    }
  }

  .PriceRangeFilter__main {
    .col.col2 {
      .content {
        width: inherit;
        margin: 0 auto;
      }

      .minPrice,
      .maxPrice {
        &__label {
          position: static;
          transform: none;

          width: 100%;
          margin-bottom: 10px;

          text-align: center;

          @include font($size: rem(13), $style: normal, $line-height: rem(20), $weight: 400, $color: $lightgrey2);
        }
      }
    }

    .action.priceRange {
      width: 347px !important;

      > .row {
        padding-bottom: 16px;

        .TextField__input {
          width: 155px;
          height: 50px;
          background-color: $blue-003;
          border: 0;

          input {
            width: 115px;

            font-size: 22px;
            font-weight: 400;
            color: $black-deep;
            text-align: center;
            letter-spacing: 0;

            border: 0;
          }
        }

        p {
          height: 100%;

          @include font($size: rem(13), $style: normal, $line-height: rem(20), $weight: 400, $color: $lightgrey2);

          margin: 50px auto 15px;
        }
      }

      .row.perCabin {
        width: 180px;
        height: 24px;
        margin: 0 auto;

        p {
          cursor: pointer;
          height: 100%;
          margin: 50px auto 15px;

          @include font($size: rem(13), $style: normal, $line-height: rem(20), $weight: 400, $color: $lightgrey2);
        }
      }
    }

    .priceperDiv {
      margin-bottom: 10px;
      text-align: center;

      @include font($size: rem(13), $style: normal, $line-height: rem(20), $weight: 400, $color: $lightgrey2);
    }

    .perSailorDiv {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0 auto;

      .BoxCheckbox:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  .ShipFilter__main {
    .content {
      display: flex;
      flex-wrap: wrap;

      width: 350px;
      margin: 0 auto;
      margin-right: 0;

      .ship-list {
        display: flex;
        flex-wrap: wrap;
        width: inherit;
        margin: 0 auto;

        .form-check {
          &:not([class*='disabled']) {
            p {
              cursor: pointer;
            }
          }
        }
      }

      .form-check {
        width: 165px;
        height: 40px;
        margin-bottom: 10px;

        @include media-breakpoint-down(sm) {
          margin-bottom: 20px;
        }

        .name {
          width: auto;
          height: 24px;
          margin: 0;

          font-size: 15px;
          font-weight: 400;
          line-height: 24px;
          color: $black;
          letter-spacing: 0;
        }

        .year {
          width: auto;
          height: 20px;
          margin: 0;

          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
          color: $grey-text;
          letter-spacing: 0;
        }

        &.disabled .name,
        &.disabled .year {
          color: $lightgrey2;
        }
      }

      .info {
        width: 250px;
        height: 40px;
        margin: 0 auto 28px;

        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: $grey-text;
        text-align: center;
        letter-spacing: 0;
      }
    }
  }

  .DeparturePortFilter__main {
    border-bottom: 0;

    .content {
      display: flex;
      flex-wrap: wrap;
      width: 330px;
      margin: 25px auto 20px;

      .port-list.action {
        display: flex;
        flex-wrap: wrap;

        .form-check {
          width: 165px;
          height: auto;

          &:not([class*='disabled']) {
            p {
              cursor: pointer;
            }
          }

          .name {
            width: 131px;
            height: auto;
            min-height: 24px;
            margin-bottom: 0;

            font-size: rem(15);
            font-weight: 400;
            line-height: rem(20);
            color: $black;
            letter-spacing: 0;
          }

          .location {
            width: 131px;
            height: auto;
            min-height: 20px;
            margin-bottom: 0;

            font-size: 13px;
            font-weight: 400;
            line-height: 12px;
            color: $grey-text;
            letter-spacing: 0;
          }

          &.disabled .name,
          &.disabled .year {
            color: $lightgrey2;
          }
        }
      }
    }
  }

  .AdvancedFilter__footer.FooterActionBar {
    &::before {
      @include media-breakpoint-up(md) {
        left: 130px;
        width: calc(100% - 130px);
      }
    }
  }
}

.AdvancedFilterHOC_mobile {
  #FlyoutParent {
    overflow: auto;

    .row {
      border-bottom: 0 !important;

      .col.col2 {
        width: 100%;
        box-shadow: none;

        .header {
          position: fixed;
          z-index: 9;

          flex-wrap: wrap;

          width: 100%;

          background-color: $gray-085;
          backdrop-filter: blur(2px);

          .title_section {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            width: 100%;
            padding-top: 20px;
            padding-bottom: 25px;

            > div {
              padding-left: 24.41px;
            }

            .title {
              width: auto;
              height: 25px;
              margin: 0;

              font-size: 17px;
              font-weight: bold;
              line-height: 25px;
              color: $black;
              text-align: center;
              letter-spacing: 0;
            }

            button.close-mobile {
              display: block;
              padding-right: 29.5px;
              background: transparent;
              border: 0;

              .Icon {
                width: 15px;
                height: 15px;
                color: $black;
              }
            }
          }

          p.value {
            display: block;

            width: 100%;
            height: auto;
            margin: 0;
            padding-bottom: 10px;

            text-align: center;

            border-bottom: 1px solid $lightwhite;

            span {
              display: flex;
              flex-direction: row;
              place-content: center center;

              width: max-content;
              max-width: 350px;
              height: auto;
              margin: 0 auto;
              padding: 12px;

              font-size: rem(12);
              font-weight: 500;
              line-height: rem(16);
              color: $grey-text;
              text-align: center;
              letter-spacing: 0;

              background-color: $lightwhite;
              border-radius: 15px;

              &.loading-skeleton {
                border-radius: 0;
              }
            }
          }
        }

        .content {
          position: relative;
          width: 100%;
          margin-top: 10px;

          .img {
            margin: 30px auto 0;
          }

          .action {
            width: 100%;
          }

          .AdvancedRefinement__nights {
            display: flex;
            justify-content: center;

            width: 100%;
            margin-top: 20px;
            margin-left: 0;
          }

          .AdvancedRefinement__weekend {
            display: flex;
            justify-content: center;
            margin: 0 auto 10px;

            .weekend-form-check {
              label {
                cursor: pointer;
              }
            }
          }

          p.subtitle {
            display: flex;
            justify-content: center;
            margin: 0 auto 21px;
          }

          .cabinType-list.action {
            width: 325px;
            height: 100%;
            margin: 0 auto;
            padding: 25px 0 0;

            .CabinTypeRefinement__card {
              cursor: pointer;
              width: 325px;
              margin: 0 auto;
              padding: 0 0 25px;

              .CabinTypeRefinement__cardImage {
                margin-bottom: 0;
              }

              .CabinTypeRefinement__cardDesc {
                width: 200px;
                height: auto;
                margin: 0;

                .CabinTypeRefinement__description {
                  width: 200px;
                  height: auto;
                }
              }
            }

            .CabinTypeRefinement__card.default {
              flex-direction: row-reverse;
              justify-content: flex-end;
              padding: 0;
            }
          }

          .action.priceRange {
            > .row {
              flex-flow: row wrap;
              width: 325px;
              margin: 0 auto;

              .minPrice,
              .maxPrice {
                margin-top: 20px;

                .TextField__input {
                  width: 145px;

                  input {
                    width: 145px;
                    height: 50px;
                    margin: 0;
                    padding: 0;

                    line-height: 50px;
                  }
                }
              }

              p.to {
                margin: 30px 0 0;
              }
            }

            .row.perCabin {
              justify-content: center;
            }
          }

          .action.ship-list,
          .port-list.action {
            align-content: start;
            max-width: 370px;
            margin: 30px auto 0;
            padding: 0 25px;

            .form-check {
              margin-bottom: 20px;
            }
          }

          p.info {
            display: none;
          }
        }
      }
    }
  }

  .DeparturePortFilter__main .content,
  .ShipFilter__main .content {
    height: max-content;
    min-height: 700px;
  }
}

.close-mobile {
  display: none;
}

.form-check-input[type='checkbox'] ~ label::before {
  cursor: pointer;
}

$inner-horizontal-padding: 7.607%;

.FilterRefinementHeader {
  position: sticky;
  height: 60px;

  .close {
    position: absolute;
    inset: auto 0 auto calc(100% - 15px - $inner-horizontal-padding);

    padding: 0;

    background: transparent;
    border: 0;
  }

  &__title {
    margin: 0;
    padding: 0;

    font-size: rem(17);
    font-weight: bold;
    line-height: 60px;
    color: $black;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: 0;
  }

  &__line {
    &::before {
      content: '';

      position: absolute;
      left: 0;

      width: 100%;
      height: 1px;

      background: $lightwhite;
    }
  }

  @include media-breakpoint-down(sm) {
    .close {
      inset: 0 0 auto auto;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 60px;
      height: 60px;

      .Icon,
      .Icon span,
      svg {
        display: block;
        width: 30px;
        height: 30px;
        color: $black;
      }
    }
  }
}

.FilterRefinement {
  overflow: auto;
  min-height: 78dvh;
  padding: 30px $inner-horizontal-padding;

  .filterRefinementMobileView {
    &__title {
      position: relative;

      width: fit-content;
      height: 24px;
      margin: 0 auto 20px;

      font-size: 15px;
      font-weight: bold;
      line-height: 24px;
      color: $black;
      text-align: center;
      letter-spacing: 0;

      &::before {
        content: '';

        position: absolute;
        bottom: 2px;
        left: 0;

        width: 100%;
        height: 8px;

        opacity: 0.2;
        background-color: $light-orange;
      }
    }

    &__mainFilter {
      box-sizing: border-box;
      width: 100%;
      height: auto;
      padding: 0 19.3px 0 16px;

      background-color: $white;
      border: 1px solid $storyblock-background;
      border-radius: 8px;
      box-shadow: 0 8px 20px 0 $black-001;

      &__button {
        position: relative;

        width: 100%;
        padding: 20px 40px 20px 0;

        text-align: left;

        background-color: transparent;
        border: 0;
        border-bottom: 1px solid $lightwhite;

        &:last-child {
          border-bottom: 0;
        }

        &__label {
          display: block;
          padding: 0;

          @include font($size: rem(12), $weight: 400, $color: $grey-text, $line-height: rem(16));

          letter-spacing: 0;

          &__options {
            display: block;

            @include font($size: rem(13), $weight: 700, $color: $black, $line-height: rem(20));
          }
        }

        &__arrow {
          position: absolute;
          top: 0;
          right: 0;

          display: flex;
          align-items: center;

          height: 100%;

          .Icon,
          .Icon span,
          svg {
            display: block;
            width: 24px;
            height: 24px;
            color: $vv-red;
          }
        }
      }
    }
  }

  .advancedFilterView {
    .heading {
      display: flex;
      justify-content: space-between;
      padding: 40px 0 10px;
      border-bottom: 1px solid $lightwhite;

      .title {
        display: flex;
        align-items: center;

        &__icon {
          display: flex;

          .Icon.Filter {
            width: 12px;
            height: 18px;
            color: $black;

            span {
              display: flex;
              width: 12px;
              height: 18px;
            }
          }
        }

        &__text {
          margin: 0 0 0 6px;

          font-size: 13px;
          font-weight: bold;
          line-height: 18px;
          color: $black;
          text-transform: uppercase;
          letter-spacing: 2px;
        }
      }
    }

    .content {
      .row {
        border-bottom: 1px solid $lightwhite !important;
      }
    }

    .Icon {
      padding-top: 0;
      color: $red;
      background: transparent;
    }
  }

  .AF__row {
    @media (max-width: $container-sm-width) {
      width: 100%;
    }
  }
}

.-refinementOpen {
  #FlyoutParent {
    @include media-breakpoint-down(sm) {
      overflow: hidden;
      height: 100vh;
    }
  }
}

.FilterRefinement.no-scroll {
  overflow: hidden;
}
